@import "../variables";
// @import "../mixins";
@import "../mq";

.carousel {
  overflow: visible;

  .slick-list {
    margin-left: -10px;
    margin-right: -10px;
  }

  .slick-slide {
    .reveal-carousel-ids & {
      position: relative;
    }
  }

  &--ticket {
    @include mq($until: M) {
      width: 110% !important;
      margin-left: -5%;
      margin-right: -5%;
      overflow: hidden;
      .slick-list {
        margin-left: 20px;
        margin-right: 20px;
        overflow: visible;
      }

      .slick-prev {
        left: 20px !important;
      }
      .slick-next {
        right: 20px !important;
      }
    }
    @include mq($from: M) {
      width: 100%;
      white-space: nowrap;
    }

    .slick-track {
      display: flex;
      margin-left: 0;
    }

    .slick-slide {
      height: auto;

      > div {
        height: 100%;
        padding-bottom: 15px;
      }
    }
  }
}

.carousel__slide {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 15px;

  .carousel--vertical & {
    width: 33.33%;
  }

  .carousel--horizontal & {
    width: 100%;
  }

  .carousel--ticket & {
    width: 100%;
    height: 100%;

    @include mq($from: 880px) {
      width: 33.33%;
      display: inline-block;
      white-space: initial;
    }

    @include mq($from: XL) {
      width: 25%;
    }

    .content-panel__row--two-column & {
      @include mq($from: 880px) {
        width: 100%;
      }

      @include mq($from: XL) {
        width: 50%;
      }
    }
  }
}

.carousel__slide-id {
  background-color: $black;
  color: white;
  cursor: pointer;
  display: none;
  left: 10px;
  padding: 5px 10px;
  position: absolute;
  top: 0;
  z-index: 1;

  &:hover {
    background-color: $dark-grey;
  }

  .reveal-carousel-ids & {
    display: block;
  }
}

.carousel__row {
  position: relative;

  .carousel--horizontal & {
    @include mq($from: M) {
      display: flex;
      align-items: center;
    }
  }
  .carousel--ticket & {
    background-color: $white;
    height: 100%;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
  }
}

.carousel__image {
  .carousel--vertical & {
    width: 100%;
  }

  .carousel--horizontal & {
    margin-bottom: 20px;

    @include mq($from: M) {
      width: 50%;
      margin-bottom: 0;
      flex-basis: 50%;
      padding-right: 20px;
    }
  }

  img {
    display: block;
    margin: 0;
  }
}

.carousel__heading {
  .content & {
    font-size: 1.5rem;
    line-height: normal;
    margin-bottom: 0.75rem;
  }
}

.carousel__text {
  .carousel--vertical & {
    width: 100%;
    padding: 20px;
  }

  .carousel--horizontal & {
    padding: 0 20px 20px 20px;

    @include mq($from: M) {
      width: 50%;
      flex-basis: 50%;
      padding: 0 0 0 20px;
    }
  }

  .carousel--ticket & {
    padding-bottom: 0;

    p {
      margin-bottom: 1rem;
    }
  }
}

.carousel__btn-group {
  display: flex;
  justify-content: flex-start;
  padding: 0 20px 20px;
}

.carousel__btn {
  display: flex;
  align-items: center;
  background-color: $black;
  color: $white;
  cursor: pointer;
  height: auto;
  padding: 1rem 2rem 1rem 2rem;
  text-decoration: none;
  text-align: left;
  vertical-align: middle;
  font-weight: 500;
  overflow: hidden;
  line-height: 1rem;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &::after {
    content: "";
    display: inline-block;
    position: relative;
    margin-left: 10px;
    width: 20px;
    height: 15px;
    z-index: 1;
    background-image: url("/img/dist/sprite/arrow-right.svg");
    background-position: 0 0;
    background-repeat: no-repeat;
    transition: all 400ms ease;
  }

  &:hover {
    text-decoration: none;

    &::before {
      box-shadow: $shadow;
    }

    &::after {
      transform: translateX(6px);
    }
  }
}

.carousel__link {
  display: inline-block;
  margin-top: 1.125rem;
  text-decoration: none;

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    text-decoration: none;

    &:after {
      box-shadow: $shadow;
    }
  }
}
