@import "../../variables";
@import "../../mq";

.twitter-feed-panel {
  padding: 24px 0;

  @include mq($from: L) {
    padding: 36px 0;
  }

  @include mq($from: XL) {
    padding: 40px 0;
  }

  &--no-padding-top {
    padding-top: 0;

    @include mq($from: L) {
      padding-top: 0;
    }

    @include mq($from: XL) {
      padding-top: 0;
    }
  }

  &--no-padding-bottom {
    padding-bottom: 0;

    @include mq($from: L) {
      padding-bottom: 0;
    }

    @include mq($from: XL) {
      padding-bottom: 0;
    }
  }

  &__content--has-additional-content {
    @include mq($from: M) {
      align-content: center;
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__timeline,
  &__additional-content {
    @include mq($from: M) {
      flex: 1;
    }
  }

  &__timeline {
    @include mq($from: M) {
      padding-right: 20px;
    }

    @include mq($from: L) {
      padding-right: 30px;
    }
  }

  &__additional-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: $baseline;

    *:last-child {
      margin-bottom: 0;
    }

    @include mq($from: M) {
      padding-left: 20px;
      padding-top: 0;
    }

    @include mq($from: L) {
      padding-left: 30px;
    }
  }
}
