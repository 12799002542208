@import "../variables";
@import "../mixins";
@import "../mq";

.hero {
  background-color: $light-background-color;
  position: relative;
  padding-bottom: 48px;

  @include mq($from: M) {
    padding-bottom: 94px;
  }
}
.hero--no-text ~ .panel-container {
  padding-top: 0;
}
.hero--no-hero {
  background-color: transparent;
}
.hero__inner {
  position: relative;
  padding: 60px 0;
  min-height: 340px;

  .hero--no-hero & {
    min-height: 0;
    padding: 0;
  }
}
.hero__curve {
  display: block;
  width: 50px;
  height: 48px;
  position: absolute;
  bottom: 74px;
  right: -1px;
  pointer-events: none;

  @include mq($from: M) {
    width: 100px;
    height: 94px;
    bottom: 93px;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    fill: white;
  }
}
.hero__text {
  display: block;
  width: 100%;
  background-color: #ffffff;
  padding: 20px;
  position: absolute;
  bottom: -80px;
  z-index: 3;
  box-shadow: $shadow;

  @include mq($from: M) {
    width: 75%;
    padding: 30px;
    position: relative;
    bottom: 0;
    box-shadow: none;
  }

  @include mq($from: L) {
    width: 50%;
    padding: 40px;
  }

  @include mq($until: M) {
    bottom: -90px;
  }
}

.hero__heading {
  color: $default-text-color;

  @include mq($until: M) {
    font-size: 2.25rem;
  }

  h1 {
    margin-bottom: 0;
  }
}

.hero__standfirst {
  color: $default-text-color;
  font-size: 1.25rem;
  line-height: normal;
  margin-top: 1.6rem;

  *:last-child {
    margin-bottom: 0;

    @include mq($until: M) {
      font-size: 1.15rem;
    }
  }
}

.hero__image-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    width: 100%;
    min-height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
//   .hero-split & {
//     min-height: 720px;

//     @include mq($from: M, $until: L) {
//       min-height: 500px;
//     }

//     @include mq($until: M) {
//       min-height: 300px;
//     }

//     &:before {
//       position: absolute;
//       content: "";
//       width: 50px;
//       height: 100px;
//       border-top-right-radius: 50px;
//       top: 0;
//       left: calc(40% - 50px);
//       border: 0px solid;
//       padding: 10px;
//       box-shadow: 0px -50px $deepblue-opacity;
//       z-index: 3;

//       @include mq($until: M) {
//         display: none;
//       }
//     }

//     &:after {
//       display: block;
//       background: $deepblue-opacity;
//       min-height: 100%;
//       width: 60%;
//       content: "";
//       position: absolute;
//       top: 0;
//       right: 0;

//       @include mq($until: M) {
//         width: 100%;
//       }
//     }
//   }

//   .hero-full-colour & {
//     min-height: 370px;

//     &:after {
//       display: block;
//       background: $deepblue-opacity;
//       min-height: 100%;
//       width: 100%;
//       content: "";
//       position: absolute;
//       top: 0;
//     }
//   }
// }
