// Image replacement
.ir {
    background-color: transparent;
    border: 0;
    overflow: hidden;

    &:before {
        content: "";
        display: block;
        height: 150%;
        width: 0;
    }
}

// Hide from both screen readers and browsers: h5bp.com/u
.hidden {
    display: none !important;
}

// Hide only visually, but have it available for screen readers: h5bp.com/v
.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: no-wrap;
    width: 1px;
    // Extends the .visuallyhidden class to allow the element to be focusable
    // when navigated to via the keyboard: h5bp.com/p
    &.focusable {
        &:active, &:focus {
            clip: auto;
            height: auto;
            margin: 0;
            overflow: visible;
            position: static;
            width: auto;
        }
    }
}

// Hide visually and from screen readers, but maintain layout
.invisible {
    visibility: hidden;
}


// Opacity helper to add to elements to allow fade animation
.fade-out,
.fade-in {
    transition: opacity .3s;
}

// Fade opacity with animation
.fade-out {
    opacity: 0 !important;
}

.fade-in {
    opacity: 1 !important;
}

// Responsive helpers to toggle visibility.
.visible-xxs,
.visible-xs,
.visible-s,
.visible-m,
.visible-l {
    display: none !important;
}

// XS, S, M, L viewports.
@include visibility(xxs);
@include visibility(xs);
@include visibility(s);
@include visibility(m);
@include visibility(l);
