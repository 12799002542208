﻿//==
// Creates float classes for each breakpoint.
//##
@mixin push-pull($bp) {
    // XXS doesn't have a min value.
    @if $bp == xxs {

        .push-#{$bp} {
            float: right ;

            [dir="rtl"] & {
                float: left ;
            }
        }

        .pull-#{$bp} {
            float: left ;

            [dir="rtl"] & {
                float: right ;
            }
        }

        @include bp-max($bp) {
            .push-#{$bp}-only {
                float: right ;

                [dir="rtl"] & {
                    float: left ;
                }
            }

            .pull-#{$bp}-only {
                float: left ;

                [dir="rtl"] & {
                    float: right ;
                }
            }
        }
    }
    @else {

        @include bp-min($bp) {

            .push-#{$bp} {
                float: right ;

                [dir="rtl"] & {
                    float: left ;
                }
            }

            .pull-#{$bp} {
                float: left ;

                [dir="rtl"] & {
                    float: right ;
                }
            }
            // Large doesn't need an "only" since it operates on min width.
            @if $bp != l {
                @include bp-max($bp) {
                    .push-#{$bp}-only {
                        float: right ;

                        [dir="rtl"] & {
                            float: left ;
                        }
                    }

                    .pull-#{$bp}-only {
                        float: left ;

                        [dir="rtl"] & {
                            float: right ;
                        }
                    }
                }
            }
        }
    }
}
