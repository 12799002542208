@import "../variables";
// @import "../mixins";
@import "../mq";

.primary-navigation {
  @media (min-width: 1000px) {
    display: flex;
    align-items: stretch;
    height: 84px;
  }
}
.primary-navigation__inner {
  display: block;

  @media (hover: none), (max-width: 999px) {
    display: none;
  }
}
.primary-navigation__more {
  color: #ffffff;
  transition: background-color 250ms ease-in-out;
  padding: 10px 15px;
  border-radius: 25px;
  align-self: center;

  &:focus {
    outline: none;
  }
  &:focus,
  &:hover {
    color: #ffffff;
    text-decoration: none;
    background-color: $dark-background-color;
  }

  @include mq($until: M) {
    margin-right: -5px;
  }

  @media (min-width: 1000px) {
    margin-left: 40px;
  }

  em {
    font-style: normal;

    &:first-of-type {
      display: none;
    }
    &:last-of-type {
      display: inline;
    }

    @media (hover: none), (max-width: 999px) {
      &:first-of-type {
        display: inline;
      }
      &:last-of-type {
        display: none;
      }
    }
  }
}
.primary-navigation__list {
  display: flex;
  height: 100%;
  //justify-content: flex-end;
}
.primary-navigation__list-item {
  padding-left: 10px;
  position: relative;
  display: flex;
  align-items: center;

  &:first-child {
    padding-left: 0;
  }

  &-actual {
    display: block;
    color: #ffffff;
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 25px;
    position: relative;
    transition: background-color 250ms ease-in-out;

    &:focus {
      outline: none;
    }
    &:focus,
    .primary-navigation__list-item:hover &,
    &--active {
      color: #ffffff;
      text-decoration: none;
      background-color: $dark-background-color;
    }

    .primary-navigation__list-item--has-subnav & {
      padding-right: 36px;

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 40%;
        right: 20px;
        z-index: 0;
        width: 9px;
        height: 9px;
        border-bottom: 2px solid #ffffff;
        border-right: 2px solid #ffffff;
        transform: rotate(45deg) translate(0, -50%);
      }
    }
  }
}

.primary-navigation__sublist {
  background-color: #ffffff;
  display: none;
  min-width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 0;
  z-index: 5;
  box-shadow: $shadow;

  .primary-navigation__list-item:hover &,
  .primary-navigation__list-item:focus & {
    display: block;
  }

  &-item {
    white-space: nowrap;

    &-actual {
      display: block;
      padding: 10px 15px;
      color: $secondary-text-color;
      line-height: normal;

      &:hover,
      &--active {
        color: $secondary-text-color;
        text-decoration: none;
        background-color: $light-background-color;
      }

      &:focus {
        outline-offset: -2px;
      }
    }
  }
}
// .primary-navigation__level3 {
//   &-item {
//     &-actual {
//       display: block;
//       padding: 8px;
//       color: $secondary-text-color;
//       line-height: 149%;
//       font-weight: 400;

//       &:hover {
//         color: $default-text-color;
//         text-decoration: none;
//         background-color: $light-background-color;
//       }
//     }
//   }
// }
