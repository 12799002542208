@import "../variables";
// @import "../mixins";
@import "../mq";

%btn {
  background-color: $cyclone;
  color: #ffffff;
  cursor: pointer;
  height: auto;
  padding: 1rem 2rem 1rem 2rem;
  text-decoration: none;
  text-align: left;
  width: 100%;
  vertical-align: middle;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  display: inline-block;
  line-height: 1rem;

  &:hover {
    background-color: $btn-hover;

    &:before {
      right: 15px;
    }
  }

  &:focus {
    outline: 2px solid $secondary-text-color;
    outline-offset: 0;
  }

  &:disabled,
  &[disabled],
  &[disabled="true"] {
    background-color: $default-text-color;
    opacity: 0.25;
  }

  @include mq($from: M) {
    width: auto;
  }
}

// Apply arrow to buttons only when the element is an anchor tag or button.
// Input elements, such as used within forms, do not support pseudo elements
// and therefore cannot have the arrow applied.
a.btn,
a.btn--primary,
a.btn--secondary,
a.btn--tertiary,
a.btn-quaternary,
button.btn,
button.btn--primary,
button.btn--secondary,
button.btn--tertiary,
button.btn--quaternary,
.btn--primary > a,
.btn--secondary > a,
.btn--tertiary > a,
.btn--quaternary > a {
  padding-right: 4rem;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 25px;
    width: 20px;
    height: 15px;
    z-index: 1;
    background-image: url("/img/dist/sprite/arrow-right.svg");
    background-position: 0 0;
    background-repeat: no-repeat;
    transition: all 400ms ease;
  }
}

.btn {
  @extend %btn;

  @include mq($from: M) {
    &.btn--fw {
      width: 100%;
    }
  }

  &.btn--primary-dark {
    &:hover {
      background-color: $dark-background-color;
    }
  }
}

// Primary buttons
a.btn--primary,
input.btn--primary,
button.btn--primary,
.btn--primary > a {
  @extend %btn;

  @include mq($until: M) {
    font-size: 16px;
  }
}

// Secondary buttons
a.btn--secondary,
input.btn--secondary,
button.btn--secondary,
.btn--secondary > a {
  @extend %btn;

  @include mq($until: M) {
    font-size: 16px;
  }
}

a.btn--tertiary,
input.btn--tertiary,
button.btn--tertiary,
.btn--tertiary > a {
  background-color: $black;
  border: 0;
  color: #ffffff;
  cursor: pointer;
  height: auto;
  min-height: 0;
  max-height: 70px;
  padding: $vertical-space / 2 $horizontal-space * 2;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  position: relative;
  overflow: hidden;
  display: flex;
  transition: background-color $transition-speed $transition-timing;

  &:hover {
    background-color: #333333;
  }
}

a.btn--quaternary,
input.btn--quaternary,
button.btn--quaternary,
.btn--quaternary > a {
  color: $deepblue;
  text-decoration: underline;
  padding-bottom: $vertical-space;
  padding-right: $horizontal-space * 2;
  border-bottom: 1px solid $deepblue;
  display: block;
  position: relative;

  &:before {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    right: 5px;
    top: calc(50% + 5px);
    transform: translateY(calc(-50% - 5px));
    mask: url(/img/dist/sprite/arrow-right2.svg) no-repeat top left;
    mask-size: contain;
    background-color: $deepblue;
    background-size: 25px 20px;
    color: $deepblue;
    z-index: 2;
  }

  &:after {
    content: "";
    background: $sheets;
    border-radius: 50px;
    display: block;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .dark & {
    color: $sheets;
    border-color: $sheets;
  }
}

a.btn--edit,
input.btn--edit,
button.btn--edit,
.btn--edit > a {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABcSURBVHgB7Y5BDcAwDAOtIRiEQBjjQhmEQRiEQkgdqa+qjyaR+qql+/ksA/7c5CUPAjH5I0qqjVyIx8aKpzy++ImsyNLLZRgRj6yd5cszWRG4feQdMrIysjKycgOmTzq31WxtDAAAAABJRU5ErkJggg==);
  background-position: 0 0;
  background-repeat: no-repeat;
  border: 0;
  color: $default-link-color;
  cursor: pointer;
  height: auto;
  min-height: 0;
  padding: 0 0 0 25px;
  text-decoration: none;
  text-align: left;
  vertical-align: middle;
  font-weight: 400;
  position: relative;
  overflow: hidden;
  font-size: 1rem;
}

button:not(.btn--tertiary),
input[type="submit"]:not(.btn--tertiary),
input[type="button"]:not(.btn--tertiary),
input[type="reset"]:not(.btn--tertiary) {
  border: none;

  &:active,
  &:hover {
    background-color: $btn-hover;
  }
}
