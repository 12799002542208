.masonry-layout {
  @include mq($from: M) {
    margin: 0 -20px;
  }
}
.masonry-layout__sizer {
  width: 100%;

  @include mq($from: M) {
    width: 50%;
  }
}

.masonry-layout__item {
  width: 100%;

  @include mq($from: M) {
    width: 50%;
    padding: 20px;
  }
}
