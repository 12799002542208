.filter-list {
  column-count: 2;

  @include mq($from: L) {
    column-count: 3;
  }
}
.filter-list__item {
  list-style: none;

  input[type="checkbox"].filter__checkbox {
    display: inline-block;
    margin: 0 8px 0 0;
    padding: 0;
    border: none;
    vertical-align: middle;
  }
  .filter__label {
    display: inline-block;
    margin: 0;
    padding: 0;
    line-height: normal;
    font-weight: 400;
    font-size: 1rem;
  }
}
