@import "../variables";
// @import "../mixins";
@import "../mq";

$header-height: 84px;

.primary-header {
  background-color: $core-background-color;
  position: relative;
  z-index: 10000;

  &__inner {
    align-items: center;
    display: flex;
    height: 100%;
    min-height: $header-height;
    justify-content: space-between;
  }

  &__logo {
    display: block;
    flex: 1;
    margin-right: 1rem;

    &-svg,
    &-image {
      display: block;
      margin: 0;
    }

    &-svg {
      fill: #ffffff;
      height: 48px;
      width: 133px;
    }

    &-image {
      max-height: 48px;
      max-width: 17.5rem;
    }

    &:focus {
      outline: 2px solid $secondary-text-color;
      outline-offset: 2px;
    }
  }
}
