@import "../variables";
@import "../mixins";
@import "../mq";

#prepform {
  width: 100% !important;

  .prep_ui_form {
    font-family: $base-font;

    .prep_ui_formelm {
      position: relative;
      margin-bottom: 1.125rem;
      border: none;
      border-bottom: 1px solid $form-border;
      font-size: 1rem;
      font-weight: normal;
      color: $secondary-text-color;

      input,
      select {
        font-family: $base-font;
        font-size: 1rem;
        font-weight: normal;
        margin: 0;
        border: none;
      }

      .prep_ui_vld {
        position: absolute;
        right: 0;
        bottom: 4px;
        margin-right: 0 !important;
        margin-bottom: 0 !important;
      }

      .prep_ui_inp {
        width: 100%;
        font-size: 16px;
        font-weight: normal;
        border: none;
        background-color: transparent;
        color: $default-text-color;

        &.prep_ui_req {
          width: calc(100% - 30px);
        }
      }

      textarea.prep_ui_inp {
        width: 100%;
      }

      select.prep_ui_inp {
        width: 100%;
      }

      input.prep_ui_date {
        background-color: transparent;
      }

      &.conf_holder {
        border-bottom: none;
      }

      div:nth-child(2) {
        margin: 0 0 2px 0;
        font-size: 1rem;
        font-weight: normal;
        color: $secondary-text-color;
      }
    }

    .prep_ui_formelm_add_right {
      width: 48%;
      margin-right: 2%;

      ~ .prep_ui_formelm {
        width: 48%;
        margin-left: 2%;
        float: right;
      }
    }
  }

  input[type="checkbox"] {
    display: none;

    + label {
      display: inline;
      margin: 0;
      vertical-align: middle;

      &:before {
        color: $black !important;
        border-radius: 0 !important;
        border-color: $black !important;
        height: 30px !important;
        margin-left: 0 !important;
        margin-right: 10px !important;
        width: 30px !important;
        vertical-align: middle !important;
      }
    }
  }
}

.prep_ui_submit {
  .std_reg_btn {
    @extend %btn;
    float: none !important;
    border: none;
    padding: 1rem 4rem 1rem 2rem !important;
    border-radius: 0 !important;

    &:hover {
      justify-content: center;
      background-color: $btn-hover;
      border: none;
      color: #ffffff;
    }
  }
}
.prep_ui_formelm_consent {
  margin-bottom: 20px;
}
.prep_ui_ffoot {
  margin-top: 20px;
  font-size: 14px;
}
