//@import "../variables";
//@import "../mixins";
//@import "../mq";

//// iframe container (inc responsive version known as 'maintain-ratio')
.iframe-container {
  max-width: 100%;

  &--maintain-ratio {
    position: relative;
    padding-bottom: 56.25%;
    width: 100%;
    display: block;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
