// Spacing
$vertical-space: 24px;
$horizontal-space: 24px;
$content-width: 90%; // Page width
$max-content-width: 1360px; // Maximum width before it just ends up centered (Containers will extend by default)
$max-narrow-content-width: 964px;
$border-radius: 12px;
$border-radius-small: 8px;
$border-thickness: 4px;
$section-border-radius-small: 48px 0 0 0;
$section-border-radius: 94px 0 0 0;

// Colours
$cyclone-not-safe: #ff4b00;
$cyclone: $cyclone-not-safe; // should eventually be #d84000, as that passes AA
$black: #000000;
$white: #ffffff;
$dark-grey: #524e4e;
$default-text-color: $black;
$secondary-text-color: $dark-grey;
$default-link-color: $black;
$btn-hover: $black;
$link-border: #cbcbcb;
$core-background-color: $black;
$form-border: #d6d6d6;
$form-placeholder: #a6a5a5;
$light-background-color: #f8f8f9;
$dark-background-color: $dark-grey;

// Colours
$juice: #ffb341;
$deepblue: #001858;
$deepblue-opacity: #001858cc;
$oxygen: #434a5c;
$seacloud: #dfdfdf;
$turk: #f5f5f5;
$turk-opacity: #78bfc951;
$sheets: #fff;
$light-blue: #e5e9f4;
$table-head: #fbfbfb;
$border-for-orange: #bad8e3;
$border-orange-on-dark: #66749c;
$body-background-color: $light-blue;
$bulletblue: #5d6c96;
$primary-highlight-color: $juice;
$hover-link-color: $primary-highlight-color;

$shadow: 0px 4px 20px rgba(169, 169, 169, 0.25); //0px 0px 15px 5px rgba(0, 0, 0, 0.1);

// Route colours
$rb1: rgb(115, 86, 191);
$rb2: rgb(0, 130, 103);
$rb4: rgb(153, 100, 77);
$rb5: rgb(248, 52, 70);
$rb6: rgb(255, 192, 67);
$rbs: rgb(248, 119, 210);

// Gradients
$juice-grad: linear-gradient(90deg, #cf6516 28.47%, #ea853a 80.07%);
$deep-opacity-grad: linear-gradient(
  to bottom,
  rgba(0, 24, 88, 1) 0%,
  rgba(0, 24, 88, 0) 100%
);
$deep-opacity-grad-reverse: linear-gradient(
  to top,
  #001858 0%,
  rgba(0, 24, 88, 0) 92.66%
);
$deep-deep-grad: linear-gradient(174.5deg, #001858 45.29%, #78bfc9 138.08%);
$image-grad: linear-gradient(
  180deg,
  rgba(0, 24, 88, 0.486) 13.7%,
  rgba(0, 24, 88, 0.186351) 44.1%,
  rgba(0, 24, 88, 0.6) 81.2%
);
$solid-blue-overlay: rgba(0, 24, 88, 0.8);

// Fonts
$base-font: "UberMoveText", "Helvetica Neue", Helvetica, sans-serif;
$heading-font: "UberMove", "Helvetica Neue", Helvetica, sans-serif;

$baseline: 1.6rem;

$minScreen: 20rem;
$maxScreen: 80rem;
$minFont: 0.85rem;
$maxFont: 1rem;

$h1: 3rem;
$h2: 2.25rem;
$h3: 2rem;
$h4: 1.5rem;
$h5: 1.125rem;

//// Media Queries
// Uncomment this if your site isn't responsive. Enjoy your trip to the past!
// $mq-responsive: false;

// Responsive breakpoints, use semantic names
$mq-breakpoints: (
  XXS: 320px,
  XS: 375px,
  S: 480px,
  M: 720px,
  L: 980px,
  XL: 1024px,
  XXL: 1280px,
  Container: 1360px
);

// Define the breakpoint from the $mq-breakpoints list that IE8 should get
$mq-static-breakpoint: XL;

// If you want to display the currently active breakpoint in the top
// right corner of your site during development, add the breakpoints
// to this list, ordered by width, e.g. (mobile, tablet, desktop).
$mq-show-breakpoints: () !default;

// Animation Variables
$transition-speed: 0.3s;
$transition-timing: ease-in-out;
$full-transition: all $transition-speed $transition-timing;
