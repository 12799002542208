.ventrata-panel {
  padding: 0 0 24px 0;

  @include mq($from: L) {
    padding: 0 0 36px 0;
  }

  @include mq($from: XL) {
    padding: 0 0 40px 0;
  }

  &--no-padding-top {
    padding-top: 0;

    @include mq($from: L) {
      padding-top: 0;
    }

    @include mq($from: XL) {
      padding-top: 0;
    }
  }

  &--no-padding-bottom {
    padding-bottom: 0;

    @include mq($from: L) {
      padding-bottom: 0;
    }

    @include mq($from: XL) {
      padding-bottom: 0;
    }
  }
}
