@import "../variables";
@import "../mixins";
@import "../mq";

.news {
  &-list {
    position: relative;

    &-pagination {
      &-container {
        text-align: right;
      }
    }

    &__item {
      width: 50%;
      float: left;
      padding: $vertical-space $horizontal-space;
      min-height: 200px;

      @include mq($until: M) {
        padding: $vertical-space 0;
      }
    }

    &__content {
      a {
        color: $deepblue;
        text-decoration: underline;
        padding-bottom: $vertical-space;
        border-bottom: 1px solid $deepblue;
        width: calc(100% - 24px);
        display: block;
        position: relative;

        &:before {
          content: "";
          width: 20px;
          height: 20px;
          position: absolute;
          right: 5px;
          top: 5px;
          mask: url(/img/dist/sprite/arrow-right2.svg) no-repeat top left;
          mask-size: contain;
          background-color: $deepblue;
          background-size: 25px 20px;
          color: $deepblue;
          z-index: 2;
        }

        &:after {
          content: "";
          background: $sheets;
          border-radius: 50px;
          display: block;
          width: 30px;
          height: 30px;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }

    &__card {
      &__date {
        text-transform: uppercase;
        font-size: $baseline / 2;
      }

      &__heading {
        h3 {
          font-size: $baseline / 1;
          margin-top: 0;
          position: relative;

          a {
            color: $deepblue;

            &:hover {
              text-decoration: none;
            }
          }

          &:after {
            content: "";
            width: 20px;
            height: 2px;
            background: $deepblue;
            position: absolute;
            bottom: -6px;
            left: 0;
          }
        }
      }

      &__image-container {
        img {
          border-radius: $border-radius;
          margin-bottom: $vertical-space;
        }
      }
    }

    &__standfirst,
    &__time {
      font-size: $baseline / 1.9;
    }

    &__time {
      &:before {
        content: "";
        width: 24px;
        height: 24px;
        display: inline-block;
        background: url("/img/dist/sprite/clock.svg") no-repeat top left;
        background-size: contain;
        margin-right: 10px;
        vertical-align: text-bottom;
      }
    }

    &__subscribe {
      &-container {
        background: $deepblue;
        border-radius: $border-radius;
        padding: $vertical-space $horizontal-space;
        color: $sheets;

        h2 {
          font-size: $baseline / 1;
          margin-top: 0;
        }
      }
    }
  }
}
