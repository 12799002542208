@import "variables";

// ////////////////////////////////// Mixins */

// Font mixins
@mixin headingFont($h) {
  font-family: $heading-font;
  font-weight: 500;
  margin-bottom: $baseline;
  font-size: $h;
  line-height: $h;
  margin-top: calc((#{$baseline} - #{$h}) + #{$baseline} * 2);

  &:first-child {
    margin-top: 0;
  }
}
// Fluid type
@mixin fluid-type(
  $properties,
  $min-screen,
  $max-screen,
  $min-value,
  $max-value
) {
  & {
    @each $property in $properties {
      #{$property}: $min-value;
    }
    @media screen and (min-width: $min-screen) {
      @each $property in $properties {
        #{$property}: calc-interpolation(
          $min-screen,
          $min-value,
          $max-screen,
          $max-value
        );
      }
    }
    @media screen and (min-width: $max-screen) {
      @each $property in $properties {
        #{$property}: $max-value;
      }
    }
  }
}
// Requires the calc-interpolation function which can also be used independently
@function calc-interpolation($min-screen, $min-value, $max-screen, $max-value) {
  $a: ($max-value - $min-value) / ($max-screen - $min-screen);
  $b: $min-value - $a * $min-screen;
  $sign: "+";
  @if ($b < 0) {
    $sign: "-";
    $b: abs($b);
  }
  @return calc(#{$a * 100}vw #{$sign} #{$b});
}
// Center an element (Element must have width)
@mixin centered {
  margin: 0 auto;
}
// make this element's immediate children inline
@mixin inline-children {
  & > * {
    display: inline;
  }
}
// No extra space
@mixin no-space {
  margin: 0;
  padding: 0;
  border: 0;
}
// Rounded corners
@mixin rounded-corners($radius: 4px) {
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}
// Clearfix
@mixin clear {
  &:before,
  &:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden;
  }
  &:after {
    clear: both;
  } // For IE 6/7 (trigger hasLayout)
  & {
    *zoom: 1;
  }
}
// Hide text
@mixin no-text {
  font-size: 0px;
  line-height: 0px;
  text-indent: -999999px;
}
// Respond-to - Quick tweaks based on common pains
// Usage:
// .awesome-div {
// float: left;
// width: 250px;
// @include respond-to(ipad) { width: 100% ;}
// @include respond-to(old-ie) { width: 125px; }
// @include respond-to(retina) { float: none; }
// }
@mixin respond-to($media) {
  @if $media == ipad {
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
      @content;
    }
  } @else if $media == retina {
    @media (min-resolution: 1.3dppx) {
      @content;
    }
  } @else if $media == old-ie {
    .lt-ie9 &,
    .old-ie & {
      @content;
    }
  }
}
// Body Class
@mixin body-class-exists($bodyclass) {
  .#{$bodyclass} & {
    @content;
  }
}

/* This mixin can be used to set the object-fit:
  @include object-fit(contain);

  or object-fit and object-position:
  @include object-fit(cover, top); */
@mixin object-fit($fit: fill, $position: null) {
  -o-object-fit: $fit;
  object-fit: $fit;

  @if $position {
    -o-object-position: $position;
    object-position: $position;
    font-family: "object-fit: #{$fit}; object-position: #{$position}";
  } @else {
    font-family: "object-fit: #{$fit}";
  }
}
