.content-panel {
  padding: 24px 0;

  @include mq($from: L) {
    padding: 36px 0;
  }

  @include mq($from: XL) {
    padding: 40px 0;
  }

  &--no-padding-top {
    padding-top: 0;

    @include mq($from: L) {
      padding-top: 0;
    }

    @include mq($from: XL) {
      padding-top: 0;
    }
  }

  &--no-padding-bottom {
    padding-bottom: 0;

    @include mq($from: L) {
      padding-bottom: 0;
    }

    @include mq($from: XL) {
      padding-bottom: 0;
    }
  }

  &--bg {
    background-color: $light-background-color;
  }
}
.content-panel__row {
  &--two-column {
    .content-panel__column:nth-child(1) {
      margin-bottom: 20px;
    }
  }

  @include mq($from: M) {
    display: flex;
    align-items: flex-start;

    &.no-flex {
      display: block;
    }

    &--two-column {
      .content-panel__column {
        flex-basis: 50%;
        flex-shrink: 0;
        max-width: 50%;

        &:nth-child(1) {
          padding-right: 20px;
          margin-bottom: 0;
        }

        &:nth-child(2) {
          padding-left: 20px;
        }
      }
    }

    &--center {
      align-items: center;
    }
  }

  @include mq($from: L) {
    &--two-column {
      .content-panel__column {
        &:nth-child(1) {
          padding-right: 30px;
        }

        &:nth-child(2) {
          padding-left: 30px;
        }

        &--wide {
          flex-basis: 70%;
          max-width: 70%;
        }

        &--narrow {
          flex-basis: 30%;
          max-width: 30%;
        }
      }
    }
  }
}
.content-panel__column {
  max-width: 100%;

  @include mq($from: M) {
    flex-grow: 1;
  }
}
.content-panel__footnote-link {
  margin-top: 20px;
}
