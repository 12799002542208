@import "../../variables";
@import "../../mq";

.panel-container {
  margin-top: -94px;
  position: relative;
  background-color: #fff;
  border-radius: 94px 0 0 0;

  @include mq($until: M) {
    margin-top: -76px;
    padding-top: 130px;
  }
}
