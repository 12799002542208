@import "../../variables";
@import "../../mq";

.panel {
  &__header {
    max-width: $max-narrow-content-width;
    margin-bottom: 1.125rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.news-list,
  &.offers-list {
    padding: 24px 0;

    @include mq($from: L) {
      padding: 36px 0;
    }

    @include mq($from: XL) {
      padding: 40px 0;
    }

    &--no-padding-top {
      padding-top: 0;

      @include mq($from: L) {
        padding-top: 0;
      }

      @include mq($from: XL) {
        padding-top: 0;
      }
    }

    &--no-padding-bottom {
      padding-bottom: 0;

      @include mq($from: L) {
        padding-bottom: 0;
      }

      @include mq($from: XL) {
        padding-bottom: 0;
      }
    }
  }
}
