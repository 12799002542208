.link-list--careers {
  max-height: 430px;
  overflow-x: auto;

  @include mq($from: L) {
    max-height: 565px;
  }
}

.link-list__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  list-style: none;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-right: 60px;
  border-bottom: 1px solid lightgray;

  .link-list--careers & {
    &:first-child {
      border-top: 1px solid lightgray;
    }
  }

  @include mq($from: L) {
    flex-direction: row;
  }
}

.link-list__meta {
  width: 100%;
}

.link-list__image {
  display: block;
  margin: 0 0 15px 0;
  min-width: 165px; // Firefox flex fix

  @include mq($from: L) {
    margin: 0 20px 0 0;
  }
}

.link-list__title {
  font-size: 1rem;
  font-family: $base-font;
  font-weight: 700;
  color: $default-text-color;
  margin-top: 0;
  margin-bottom: 0;
  line-height: normal;

  .link-list--careers & {
    font-size: 1.125rem;
    padding: 4px 0;
  }
}

.link-list__link {
  color: $default-text-color;

  &:hover {
    text-decoration: none;

    &:before {
      @include mq($from: M) {
        right: 15px;
      }
    }

    &:after {
      box-shadow: $shadow;
    }
  }

  &:before {
    content: "";
    width: 20px;
    height: 15px;
    background-image: url("/img/dist/sprite/arrow-right-dark.svg");
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    transition: all 400ms ease;

    @include mq($from: M) {
      right: 25px;
    }
  }

  &:after {
    content: "";
    width: calc(100% + 15px);
    height: 100%;
    position: absolute;
    top: 0;
    left: -15px;
  }
}

.link-list__text {
  display: block;
  margin-top: 6px;

  p {
    margin-bottom: 0;
  }

  .link-list--careers & {
    color: $dark-grey;
  }
}
.link-list__row {
  display: flex;
  justify-content: space-between;

  .link-list--careers & {
    margin-top: 10px;
  }
}
.link-list__count {
  display: block;
  margin-bottom: 10px;
  font-size: 0.875rem;
  color: $dark-grey;
}
.link-list__col {
  max-width: 50%;
  color: $black;

  &--right {
    text-align: right;
  }
}
.link-list__subheading {
  display: block;
  font-size: 0.875rem;
  font-style: normal;
  color: $dark-grey;
}
