@font-face {
  font-family: "UberMove";
  src: url("../../fonts/Uber_Move-Medium.eot"); /* IE9 Compat Modes */
  src: url("../../fonts/Uber_Move-Medium.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../../fonts/Uber_Move-Medium.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../../fonts/Uber_Move-Medium.woff")
      format("woff"); /* Pretty Modern Browsers */
}
@font-face {
  font-family: "UberMoveText";
  src: url("../../fonts/Uber_Move_Text-Regular.eot"); /* IE9 Compat Modes */
  src: url("../../fonts/Uber_Move_Text-Regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../../fonts/Uber_Move_Text-Regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../../fonts/Uber_Move_Text-Regular.woff")
      format("woff"); /* Pretty Modern Browsers */
}

@import "variables";
@import "mixins";

html {
  @include fluid-type(font-size, $minScreen, $maxScreen, $minFont, $maxFont);
  height: 100%;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body {
  line-height: $baseline;
  font-family: $base-font;
  background-color: #ffffff;
  color: $default-text-color;
  overflow-x: hidden;
  overflow-y: scroll;
  min-height: 100%;
  min-height: 100vh;
  font-weight: 400;

  &.mm-ocd-opened {
    @media (hover: hover), (min-width: 100px) {
      overflow-y: scroll;
      position: fixed;
      max-width: 100%;
      min-width: 100%;
    }
  }

  > .secondary-navigation {
    display: none;
  }
}
h1 {
  @include headingFont($h1);
  line-height: 118.5%;
}

h2 {
  @include headingFont($h2);
}

h3 {
  @include headingFont($h3);
}

h4 {
  @include headingFont($h4);
}

h5 {
  @include headingFont($h5);
  font-weight: normal;
}
p {
  margin-top: 0;
  line-height: $baseline;
  margin-bottom: $baseline;
}
a {
  color: $default-link-color;
  text-decoration: none;

  &:hover {
    color: $default-link-color;
    text-decoration: underline;
  }
  &:focus {
    outline: 2px solid $secondary-text-color;
    outline-offset: 2px;
  }
}
ul {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}
img {
  margin-bottom: 1em;
}
.skip-link {
  position: absolute !important;
  left: -999px;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  z-index: 999;
}

.show-vertical-rhythm {
  background-image: linear-gradient(
    rgba(200, 50, 50, 0.25) 1px,
    transparent 1px
  );
  background-size: 1px $baseline;
  padding-bottom: 1px;
  margin-bottom: $baseline;
}

img[data-sizes="auto"] {
  display: block;
  width: 100%;
}

sup {
  color: $juice;
  font-weight: 700;
}
