.carousel,
#tweets {
  .slick-arrow {
    width: 20px;
    height: 20px;
    min-height: auto;
    padding: 0;
    top: auto;
    bottom: 0;
    z-index: 2;
    text-indent: -999px;
    overflow: hidden;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;

    &:hover {
      background-color: transparent;
    }

    &:focus {
      outline: 2px solid $dark-background-color;
      outline-offset: 2px;
      color: white;
      opacity: 1;

      &:hover {
        color: white;
        background-color: transparent;
      }
    }

    @include mq($from: Container) {
      top: 50%;
      bottom: auto;
      transform: translateY(-50%);
    }
  }

  .slick-prev {
    left: 0;
    background-image: url("/img/dist/sprite/carousel-prev.svg");

    @include mq($from: Container) {
      left: -25px;
    }
  }

  .slick-next {
    right: 0;
    left: auto;
    background-image: url("/img/dist/sprite/carousel-next.svg");

    @include mq($from: Container) {
      right: -25px;
    }
  }

  .slick-dots {
    height: 14px;
    text-align: center;

    @include mq($from: Container) {
      text-align: left;
    }

    li {
      display: inline-block;
      width: auto;
      height: auto;
      list-style: none;
      vertical-align: top;
      margin: 0 10px 0 0;

      button {
        width: 12px;
        height: 12px;
        padding: 0;
        min-height: 0;
        border: none;
        text-indent: -99px;
        overflow: hidden;
        background-color: $form-border;

        &:focus {
          outline: 2px solid $dark-background-color;
        }

        &:before {
          display: none;
        }
      }

      &.slick-active {
        button {
          background-color: #000000;
        }
      }
    }
  }
}
