// Styles which should be present in both the front end AND the back end (WYSIWYG editors)
@import "variables";

img {
  margin-bottom: 1.25rem;
}
// img[style*="float: left"] {
//   margin: 0 1em 1em 0;
//   max-width: 40%;
// }
// img[style*="float: right"] {
//   margin: 0 0 1em 1em;
//   max-width: 40%;
// }
// img[style*="margin-left: auto"] {
//   margin-bottom: 1em;
//   max-width: 100%;
//   width: auto !important;
// }

h1,
h2,
h3,
h4,
h5 {
  margin-top: 0;
  margin-bottom: 1.25rem;
  line-height: 1.25;

  &:last-child {
    margin-bottom: 0;
  }
}
p {
  &:last-child {
    margin-bottom: 0;
  }

  a:not(.btn--primary) {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .btn--primary > a {
    text-decoration: none;
  }
}

ul,
ol {
  padding-left: 24px;
  font-size: 1rem;
  line-height: 1.725rem;
  margin-bottom: 20px;
}

ul li {
  list-style: disc;
}

ol li {
  list-style: decimal;
}

table {
  width: 100% !important;
  border: 10px solid $sheets;
  border-collapse: collapse;
  box-shadow: $shadow;

  tr {
    th,
    td {
      border: none;
      border-bottom: 1px solid $dark-background-color;
    }

    th {
      font-weight: 700;
    }

    td {
      img {
        margin-bottom: 0;
      }
    }

    &:last-child {
      th,
      td {
        border-bottom: none;
      }
    }
  }

  // Scrollable tables
  &.scrollable {
    font-size: 0.75rem;
    line-height: 1.5;
    margin-top: 0;
    user-select: none;
  }
}

.timetable {
  border: 10px solid $sheets;
  box-shadow: $shadow;
  cursor: move;
  padding-left: 160px;
  overflow-x: scroll;
  overflow-y: visible;
  width: 100%;

  table {
    border: 0;
    box-shadow: none;
    margin-bottom: 0;

    tr {
      td,
      th {
        border-bottom: 0;
        font-weight: bold;

        &:first-child {
          background-color: $sheets;
          margin-top: 1px;
          position: absolute;
          width: 170px;
          left: 0;
        }
      }
    }

    thead tr {
      th {
        &:first-child {
          margin-top: 0;
        }

        &.is-route {
          color: white;
        }

        &.rb1 {
          background-color: $rb1;
        }

        &.rb2 {
          background-color: $rb2;
        }

        &.rb4 {
          background-color: $rb4;
        }

        &.rb5 {
          background-color: $rb5;
        }

        &.rb6 {
          background-color: $rb6;
        }

        &.rbs {
          background-color: $rbs;
        }
      }
    }

    tbody {
      tr td:first-child {
        font-weight: normal;
      }

      td {
        &.rb1 {
          background-color: rgba($rb1, 0.1);
        }

        &.rb2 {
          background-color: rgba($rb2, 0.1);
        }

        &.rb4 {
          background-color: rgba($rb4, 0.1);
        }

        &.rb5 {
          background-color: rgba($rb5, 0.1);
        }

        &.rb6 {
          background-color: rgba($rb6, 0.1);
        }

        &.rbs {
          background-color: rgba($rbs, 0.1);
        }
      }

      tr {
        &:first-child td:first-child {
          margin-top: 0;
        }

        &:nth-child(even) td {
          &.rb1 {
            background-color: rgba($rb1, 0.015);
          }

          &.rb2 {
            background-color: rgba($rb2, 0.015);
          }

          &.rb4 {
            background-color: rgba($rb4, 0.015);
          }

          &.rb5 {
            background-color: rgba($rb5, 0.015);
          }

          &.rb6 {
            background-color: rgba($rb6, 0.015);
          }

          &.rbs {
            background-color: rgba($rbs, 0.015);
          }
        }
      }
    }
  }
}
