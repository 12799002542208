.accordion {
  border: none;
  margin: 0;
}
.accordion__list {
  margin: 0;
}
.accordion__title {
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  padding: 1rem 40px 1rem 0;
  font-size: 1.125rem;
  font-weight: 700;
  cursor: pointer;
  position: relative;

  &:after {
    content: "";
    border: solid #000000;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    position: absolute;
    top: 45%;
    right: 15px;
    width: 10px;
    height: 10px;
    transition: all $transition-speed $transition-timing;
  }

  &.is-open {
    &:after {
      top: 40%;
      border-width: 0 2px 2px 0;
      transform: rotate(225deg);
      -webkit-transform: rotate(225deg);
    }
  }
}
.accordion__content {
  min-height: 0;
  max-height: 0;
  margin: 0;
  overflow: hidden;
  color: $secondary-text-color;
  transition: max-height $transition-speed $transition-timing;
  border-bottom: 1px solid $form-border;

  &:after {
    content: "";
    display: block;
    height: 15px;
  }

  &.child-is-default-open {
    max-height: 1000px;
  }
}
