.vnt-btn[class] {
  display: flex !important;
  align-items: center;
  background-color: $black !important;
  color: $white !important;
  cursor: pointer !important;
  height: auto !important;
  padding: 1rem 2rem 1rem 2rem !important;
  text-decoration: none !important;
  text-align: left !important;
  vertical-align: middle !important;
  font-weight: 500 !important;
  overflow: hidden !important;
  line-height: 1rem !important;
  border-radius: 0 !important;

  &::after {
    content: "";
    display: inline-block;
    position: relative;
    margin-left: 10px;
    width: 20px;
    height: 15px;
    z-index: 1;
    background-image: url("/img/dist/sprite/arrow-right.svg");
    background-position: 0 0;
    background-repeat: no-repeat;
    transition: all 400ms ease;
  }

  &:hover {
    text-decoration: none !important;

    &::after {
      transform: translateX(6px);
    }
  }
}
