.umbraco-forms-fieldset {
  // Form elements
  legend {
    display: none;
  }

  label {
    display: block;
    float: left;
    clear: right;
    user-select: none;
    margin-bottom: 4px;
    font-size: 16px;
  }

  .note,
  .help-block {
    float: right;
    font-size: 0.875rem;
    margin-bottom: 4px;
    display: block;
  }

  .umbraco-forms-indicator {
    color: $cyclone-not-safe;
  }

  .checkboxes,
  .checkboxlist,
  .checkbox,
  .radios,
  .radiobuttonlist,
  .dataconsent {
    @include clear;

    label {
      border: 0;
      width: auto;
    }
  }

  .umbraco-forms-field .radiobuttonlist {
    input,
    label {
      display: inline-block !important;
      float: unset !important;
    }
  }

  input[type="text"],
  textarea,
  input[type="email"],
  input[type="tel"],
  input[type="password"],
  input[type="file"],
  select {
    font-size: 16px;
    background-color: $light-background-color;
    background-image: none;
    border-radius: 0;
    border: 1px solid $form-border;
    box-shadow: none;
    color: $default-text-color;
    display: block;
    line-height: 1.42857143;
    margin-bottom: 6px;
    padding: 12px 12px;
    transition: border-color ease-in-out 0.15s;
    width: 100%;
    height: 48px;

    &:focus {
      border-color: $light-blue;
    }

    &.input-hp {
      display: none;
    }
  }

  select {
    -webkit-appearance: auto;
  }

  input[type="text"],
  textarea,
  input[type="email"],
  input[type="tel"],
  input[type="password"] {
    &:-moz-read-only,
    &:read-only {
      cursor: default;
      background: $form-placeholder;
    }
  }

  input[type="file"] {
    &:-moz-read-only,
    &:read-only {
      cursor: default;
    }
  }

  input[type="radio"],
  input[type="checkbox"] {
    float: left;
    margin: 2px 10px 0 0 !important;
    clear: left;

    + .label,
    + label {
      margin-bottom: 5px;
      margin-top: 0;
    }
  }
}

.umbraco-forms-field-wrapper {
  align-items: flex-start;
  flex-direction: column;
  clear: both;
  display: flex;
  margin-bottom: 10px;

  h2 {
    font-size: 1.25rem;
    line-height: 1.8rem;
  }

  .checkbox & {
    width: 100%;
  }

  .titleanddescription & {
    > div > *:last-child {
      margin-bottom: 0;
    }
  }

  .dataconsent & {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

// Recaptcha v2
.recaptchatable input[type="text"] {
  line-height: 18px !important;
}

#recaptcha_area,
#recaptcha_table {
  table-layout: auto !important;
  width: auto !important;
  clear: both;
}

.recaptcha_is_showing_audio embed {
  height: 0;
  width: 0;
}

#recaptcha_area input[type="text"] {
  display: inline-block !important;
  height: auto !important;
}

#recaptcha_table td {
  line-height: normal;
}

#recaptcha_image {
  box-sizing: content-box !important;
}

// Validation
span.field-validation-error,
input.input-validation-error,
textarea.input-validation-error {
  color: $cyclone-not-safe;
  border-color: $cyclone-not-safe;
}

span.field-validation-error {
  display: block;
  clear: both;
  font-size: 0.875rem;
  margin: 0;

  .dataconsent & {
    width: 100%;
  }
}

.validation-summary-errors {
  color: $cyclone-not-safe;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: $vertical-space * 2;
  font-size: 16px;
  border: 1px solid $cyclone-not-safe;
}

input.input-validation-error,
textarea.input-validation-error {
  border-color: $cyclone-not-safe;
  border: 2px solid $cyclone-not-safe;
  padding: 9px 17px;
}

.readonly,
[readonly] {
  cursor: pointer;
}

.help-block {
  float: right;
}

// Pikaday overrides
body .pika-single {
  border-radius: 0;
  font-family: inherit;

  button {
    border-radius: 0;
    min-height: 0;
    text-align: center;
  }

  td {
    border: 1px solid $form-border;
  }

  .pika-button {
    color: $default-text-color;
    padding: 8px;

    &:hover {
      background-color: $light-blue;
      border-radius: 0;
      color: $default-text-color;
    }
  }

  .is-today .pika-button {
    &,
    &:hover {
      color: $default-text-color;
    }
  }

  .is-selected .pika-button {
    background-color: $light-blue;
    border-radius: 0;
    box-shadow: none;
    color: $default-text-color;
    pointer-events: none;
  }

  .pika-lendar {
    margin: 10px;
  }

  .pika-title {
    align-items: center;
    color: $default-text-color;
    display: flex;
    padding-bottom: 5px;

    select {
      border: 0;
      color: inherit;
      height: 1rem;
      top: 0;
    }
  }

  .pika-label {
    color: $default-text-color;
    float: none;
    font-family: $base-font;
    height: auto;
    order: 3;
    padding: 0 3px;

    &:first-of-type {
      order: 2;
    }
  }

  .pika-prev,
  .pika-next {
    float: none;
    height: $vertical-space;
    opacity: 0.25;

    &:hover {
      background-color: $light-blue;
    }
  }

  .pika-prev {
    left: 3px;
    margin-right: auto;
    order: 1;
  }

  .pika-next {
    margin-left: auto;
    order: 4;
    right: 3px;
  }

  .pika-select {
    padding: 0;
  }

  .pika-table {
    font-family: $base-font;
    margin: 0;

    abbr {
      text-decoration: none;
    }
  }
}

.umbraco-forms-navigation {
  padding-top: 20px;

  .next,
  .btn--primary {
    float: right;
    width: auto;
  }

  .btn--secondary {
    width: auto;
  }
}

.grecaptcha-badge {
  bottom: 95px !important;
  z-index: 2;
}
