// Normalize button borders, color, and padding.
// 1: Remove rounded corners on iPhone.
// 2: Remove 300ms touch delay.
button,
input[type="submit"],
input[type="button"],
input[type="reset"] {
    -webkit-appearance: none; // 1
    border-radius: 0; // 1
    color: inherit;
    cursor: pointer;
    display: inline-block;
    min-height: $form-input-height;
    padding: 0 1rem;
    margin-bottom: 0;
    background: $button-background;
    border: $button-border;
    touch-action: manipulation; // 2
    text-decoration: none;

    &:hover {
        background-color: darken($button-background, 5);
    }

    &:active {
        background-color: lighten($button-background, 5);
        outline: none;
    }
    // 1. Fix IOS 6/7 opacity issue
    &[disabled] {
        background-color: lighten($button-background, 5);
        color: $button-color-disabled;
        cursor: not-allowed;
        opacity: 1; // 1
        pointer-events: none
    }
    // Inline buttons designed for rendering next to inputs.
    // 1. Fix width on Chrome.
    .input-group & {
        min-width: 100%; // 1
    }
}

// Reset width for inputs
input {
    &[type="submit"],
    &[type="reset"],
    &[type="button"] {
        max-width: none;
        width: auto;
    }
}

// Grouped buttons.
.btn-group > * {
    float: left;

    [dir="rtl"] & {
        float: right;
    }
}
