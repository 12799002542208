.article-meta-panel {
  padding: 24px 0 0 0;

  @include mq($from: L) {
    padding: 36px 0 0 0;
  }

  @include mq($from: XL) {
    padding: 40px 0 0 0;
  }
}
