.article-card {
  position: relative;
}

.article-card__image {
  img {
    display: block;
    width: 100%;
    margin: 0;
  }
}

.article-card__meta {
  padding: 1.125rem 1.125rem 4rem 1.125rem;
}
.article-card__heading {
  font-size: 1.5rem;
  line-height: normal;
}
.article-card__link {
  text-decoration: none;

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    text-decoration: none;

    &:after {
      box-shadow: $shadow;
    }
  }
}
.article-card__text {
  p:last-child {
    margin-bottom: 0;
  }
}
.article-card__date {
  display: block;
  padding-top: 1.6rem;
  color: $secondary-text-color;
}

.article-card__masked-link.link {
  // link added to increase score
  position: absolute;
  bottom: 1.125rem;
  left: 1.125rem;
}
