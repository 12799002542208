@import "../variables";
@import "../mixins";
@import "../mq";

//// Footer
.primary-footer {
  background-color: $core-background-color;
  padding-top: 48px;
  padding-bottom: 24px;
  position: relative;
  border-radius: $section-border-radius-small;

  @include mq($from: M) {
    border-radius: $section-border-radius;
  }

  &__inner {
    color: #ffffff;
  }

  &__row {
    display: flex;
    flex-direction: column-reverse;

    @include mq($from: M) {
      flex-direction: row;
      justify-content: space-between;
    }

    &--border {
      border-top: 1px solid $form-border;
      padding-top: 15px;

      @include mq($from: M) {
        padding-top: 30px;
        flex-direction: column-reverse;
      }

      @include mq($from: L) {
        flex-direction: row;
      }
    }
  }

  &__column {
    @include mq($from: M) {
      flex-basis: 50%;
    }
  }

  &__logo {
    display: block;
    flex: 1;
    margin-bottom: 24px;
    margin-right: 1rem;

    &-svg,
    &-image {
      display: block;
      margin: 0;
    }

    &-svg {
      fill: #ffffff;
      height: 48px;
      width: 133px;
    }

    &-image {
      max-height: 48px;
      max-width: 17.5rem;
    }

    &:focus {
      outline: 2px solid $secondary-text-color;
      outline-offset: 2px;
    }
  }

  &__nav-wrapper {
    display: flex;
    margin-bottom: 30px;
    justify-content: space-between;

    @include mq($from: M) {
      justify-content: space-around;
    }
  }

  &__nav h4 {
    &,
    a {
      color: #ffffff;
    }
  }

  &__list li a {
    color: #ffffff;
  }

  &__legal-wrapper {
    display: flex;
    flex-direction: column-reverse;

    @include mq($from: M) {
      display: block;
    }
  }

  &__legal-nav {
    display: inline-block;
    margin-top: 6px;
    margin-bottom: 12px;

    @include mq($from: M) {
      margin-top: 0;
      margin-bottom: 0;
    }

    li {
      display: inline-block;
      margin: 0 0 0 4px;
      padding: 0 0 0 6px;
      border-left: 1px solid #ffffff;
      line-height: 1.4;

      &:first-child {
        margin-left: 0;
        padding-left: 0;
        border-left: none;
      }

      a {
        display: inline-block;
        color: #ffffff;

        &:hover {
          color: #ffffff;
        }
      }

      @include mq($from: M) {
        &:first-child {
          margin-left: 6px;
          padding-left: 6px;
          border-left: 1px solid #ffffff;
        }
      }
    }
  }

  &__curve {
    display: block;
    width: 50px;
    height: 48px;
    position: absolute;
    bottom: calc(100% - 1px);
    right: -1px;
    z-index: 2;
    pointer-events: none;

    @include mq($from: M) {
      width: 100px;
      height: 94px;
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
      margin: 0;
      fill: #000000;
    }
  }

  &__newsletter-form {
    margin-bottom: 20px;

    .prep_ui_form .prep_ui_formelm {
      div:nth-child(2) {
        color: #ffffff;
      }

      &.conf_holder {
        margin-bottom: 0;

        label {
          color: #ffffff;
        }
      }

      input.prep_ui_inp {
        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $form-border;
          opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: $form-border;
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: $form-border;
        }
      }
    }
  }

  &__social {
    margin-bottom: 15px;

    @include mq($from: L) {
      text-align: right;
      margin-bottom: 0;
    }

    &-item {
      display: inline-block;
      margin-left: 15px;

      &:first-of-type {
        margin-left: 0;
      }
    }

    &__link-icon {
      width: 16px;
      height: 16px;
      fill: #ffffff;
    }
  }
}
