@import "../variables";
// @import "../mixins";
@import "../mq";

.secondary-navigation {
  margin-top: 84px; // Height of header

  &__list {
    font-weight: 400;
    padding-bottom: 120px !important;
  }

  .secondary-navigation__list-item {
    &--hide {
      display: none;

      @media (hover: none), (max-width: 999px) {
        display: block;
      }
    }
  }
}

.mm-spn {
  font-family: $base-font !important;

  a {
    color: $default-text-color;

    &:hover {
      text-decoration: none;
    }
    &:focus {
      outline-offset: -2px;
    }
  }

  li {
    &:before {
      color: $default-text-color;
      opacity: 1;
    }
  }

  &--light {
    color: $default-text-color !important;
    background: $light-background-color !important;
  }

  &--navbar {
    &:after {
      opacity: 1 !important;
    }
  }
}

[data-mm-spn-title] {
  font-weight: 700;
  opacity: 1;
}
