// @import "../variables";
@import "../mixins";
// @import "../mq";

.cookie-message {
  background: #000000;
  background: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  font-size: 14px;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 50;

  @include respond-to(old-ie) {
    display: none;
  }

  a {
    color: #ffffff;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
      color: #ffffff;
    }
  }

  &.cookie-message--visible {
    transform: translateY(0);
    @include respond-to(old-ie) {
      display: block;
    }
  }
}
.cookie-message__inner {
  padding: 6px 0;
}
.cookie-message__close {
  float: right;
  color: #ffffff;
  min-height: 0;
}
