.app-promotion {
  display: flex;
  align-items: center;
}
.app-promotion__column {
  flex-basis: 50%;

  &--image {
    max-width: 232px;
  }
  &:nth-child(1) {
    padding-right: 20px;
  }
  &:nth-child(2) {
    padding-left: 20px;
  }
}
