.link {
  display: inline-block;
  position: relative;
  padding-bottom: 2px;
  font-size: 1rem;
  font-weight: 400;
  font-family: $base-font;
  color: $default-link-color;
  text-decoration: none;
  border-bottom: 1px solid $link-border;

  &:after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 0;
    height: 1px;
    background-color: $default-link-color;
    transition: all 500ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  &:hover {
    color: $default-link-color;
    text-decoration: none;

    &:after {
      width: 100%;
    }
  }

  &--masked {
    a:hover & {
      &:after {
        width: 100%;
      }
    }
  }
}
