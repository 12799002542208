.card-grid {
  width: auto;
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px;
  // max-width: calc(90vw + 40px);

  @include mq($from: M) {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    padding: 10px;
  }
  // @include mq($from: $max-content-width) {
  //   max-width: calc(#{$max-content-width} + 40px);
  // }
}

.card-grid__item {
  width: 100%;

  @include mq($from: M) {
    padding: 10px;
    width: 50%;
  }

  @include mq($from: L) {
    width: 33.33%;
    max-width: 33.33%;
  }

  // &--image-loaded {
  //   @include mq($from: M) {
  //     padding-left: 0;
  //     padding-right: 0;
  //   }
  // }
}

.card-grid__no-results {
  display: none;
  padding: 2rem 0;
  text-align: center;

  h2 {
    margin: 0;
  }
}
