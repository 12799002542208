.icon-list {
  @include mq($from: XS) {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-right: -20px;
  }
}

.icon-list__item {
  margin-bottom: 20px;

  @include mq($from: XS) {
    flex-basis: 50%;
    max-width: 50%;
    padding: 20px;
    margin-bottom: 0;
  }
  @include mq($from: L) {
    flex-basis: 25%;
    max-width: 25%;
  }

  .content-panel__row--two-column & {
    @include mq($from: XS) {
      flex-basis: 50%;
      max-width: 50%;
    }
    @include mq($from: L) {
      flex-basis: 50%;
      max-width: 50%;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }
}
.icon-list__title {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  line-height: normal;
}
