.filter {
  position: relative;
}
.filter__toggle {
  &.filter__toggle--open {
    border-bottom: none;
    padding-bottom: 8px;

    & + .filter__tag-list {
      display: block;
    }
  }

  @include mq($from: M) {
    width: auto;
  }
}
.filter__tag-list {
  display: none;
  width: 100%;
  position: absolute;
  top: calc(100% - 2px);
  right: 0;
  background-color: #fff;
  box-shadow: $shadow;
  padding: 18px 24px;
  text-align: left;
  z-index: 2;

  @include mq($from: M) {
    width: auto;
    min-width: 360px;
  }
}
.filter__tag-list-item {
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
  }
}
input[type="checkbox"].filter__checkbox {
  display: inline-block;
  margin: 0 8px 0 0;
  padding: 0;
  border: none;
  vertical-align: middle;
}
.filter__label {
  display: inline-block;
  margin: 0;
  padding: 0;
  line-height: normal;
  font-weight: 400;
  font-size: 1rem;
}
