.billboard-panel {
  position: relative;
  padding-top: 0;
  padding-bottom: 12px;

  @include mq($from: L) {
    padding-bottom: 18px;
  }

  @include mq($from: XL) {
    padding-bottom: 20px;
  }

  &--no-padding-bottom {
    padding-bottom: 0;

    @include mq($from: L) {
      padding-bottom: 0;
    }

    @include mq($from: XL) {
      padding-bottom: 0;
    }
  }
}

.billboard-panel__head,
.billboard-panel__foot {
  display: block;
  width: 100%;
  height: 28px;
  background-color: #ffffff;
  position: relative;
  z-index: 1;
}
.billboard-panel__head {
  top: 28px;
  border-radius: 0 0 28px 0;

  .billboard-panel__curve {
    top: calc(100% - 1px);
    left: 0;
    transform: rotate(180deg);
  }
}
.billboard-panel__foot {
  bottom: 28px;
  border-radius: 28px 0 0 0;

  .billboard-panel__curve {
    bottom: calc(100% - 1px);
    right: 0;
  }
}
.billboard-panel__curve {
  display: block;
  width: 50px;
  height: 48px;
  position: absolute;
  bottom: 47px;
  right: -1px;
  z-index: 2;

  @include mq($from: M) {
    width: 100px;
    height: 94px;
    bottom: 93px;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    fill: white;
  }
}

.billboard-panel__background {
  img {
    margin: 0;
  }
}

.billboard-panel__inner {
  position: absolute;
  left: 50%;
  bottom: -15px;
  transform: translateX(-50%);
  z-index: 2;

  @include mq($from: M) {
    bottom: 12px;
  }
}
.billboard-panel__overlay {
  width: 100%;
  padding: 24px;
  background-color: #ffffff;
  box-shadow: $shadow;
  position: relative;
  z-index: 2;

  @include mq($from: M) {
    width: 75%;
  }
  @include mq($from: L) {
    width: 50%;
  }
  @include mq($from: XL) {
    width: 40%;
  }

  p:last-child {
    margin-bottom: 0;
  }
}
